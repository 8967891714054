import Footer from '../footer';

const ContactUs = () => {
    return (
       <>
        <div className="contact">
            <center><h3><b>Contact Us</b></h3>If you have any query or suggestion, Please email us on <b><a href="cdn-cgi/l/email-protection.php" class="__cf_email__" data-cfemail="c3b0a2b7b7a2a5a2b0b7a8aaada4f4fbf083a4aea2aaafeda0acae">[email&#160;protected]</a></b>.
                <p>जो लोग अपना खुद का सट्टा गेम चलते है वो लोग अपने सट्टा गेम का रिजल्ट हमारी वेबसाइट पर पड़वा सकते है.</p>

                <p className="mb-0">  <a href="https://wa.me/qr/LHVDWUX5G47DN1"><button type="button" 
                class="btn contact-button btn-success"> <font size="4px"><b>WhatsApp now</b></font></button></a> </p>
                <p>Admin</p>  </center>

                
        </div>
         <Footer/>
        </>

    )
}

export default ContactUs;